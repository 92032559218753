h1 {
  margin-top: 40px;
  text-align: center;
}

h3,
h4 {
  text-align: center;
}

.blog-page {
  background-image: url("../images/fern-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.description {
  text-align: center;
  margin: 20px;
  color: gray;
}

.card-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  max-width: 1000px;
  margin: 0 auto;
}

.card {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  background-color: white;
  box-shadow: 0 3px 5px #00000060;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  margin: 30px;
}

.description img {
  display: none;
}

.button {
  color: white;
  background: blueviolet;
  /* border-radius: 40px; */
  padding: 10px 20px;
  text-decoration: none;
  justify-self: flex-end;
  width: fit-content;
  box-shadow: 0 3px 3px #00000060;
}

.button:hover {
  background-color: black;
}

@media screen and (min-width: 768px) {
  .card-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
