.events-page {
  background-image: url("../images/fern-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.calendar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0 30px 0;
}
/* 
body > span {
  display: hidden;
} */
