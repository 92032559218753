.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background-image: url("../images/fern-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.iframe-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  height: 1080px;
}

.iframe-wrapper > iframe {
  width: 150%;
  height: 100%;
}
