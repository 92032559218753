.footer {
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 13rem;
  width: 100%;
  line-height: 23px;
  /* align-self: baseline; */
}

.footer-header {
  margin-left: 20px;
}

.footer-body {
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  color: #d3d3d3;
  padding: 10px 20px 20px 20px;
  width: 50%;
}

.footer-left {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.footer-made {
  display: flex;
  color: gray;

  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 20px;
  font-size: small;
}

.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
}

.sm-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.sm-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 12px;
  margin: 10px 0 10px 0;
  padding: 5px 3px 0 3px;
}

.facebook .instagram {
  width: 30px;
  height: 30px;
}

/* .footer-links {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
} */

@media screen and (max-width: 1024px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: fit-content;
    /* width: 110%; */
  }

  .footer-left {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-body {
    width: 75%;
  }

  .footer-made {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
  }
}
