/* .submenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 5.5rem;
  height: fit-content;
  position: absolute;
  list-style: none;
  text-align: start;
  right: 22rem;
  top: 3rem; */
/* bottom: 2rem; */
/* background-color: black;
  float: left;
  overflow: hidden;
  z-index: 1;
} */

/* .submenu li {
  cursor: pointer;
} */

.submenu-item {
  display: block;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #d3d3d3;
  margin-top: 3px;
  margin-bottom: 3px;
  /* background-color: black;  */
  z-index: 0;
  opacity: 1;
}

.submenu-item:hover {
  color: white;
}

/* .submenu-inactive {
  display: none;
} */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  opacity: 1;
  width: 90px;
  top: 35px;
  /* left: 1px; */
  background-color: blueviolet;
  border-radius: 8px;
  color: #d3d3d3;
  padding: 0 0 0 5px;
  font-weight: 500;
}

.dropdown-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 75px;
  margin-bottom: 20px;
  color: #d3d3d3;
  text-decoration: none;
  font-weight: 500;
}

.dropdown-btn:hover {
  color: white;
  background-color: blueviolet;
  border-radius: 8px 8px 0 0;
}

.dropdown-btn-active {
  color: #8f76c2;
}

.dropdown:hover .dropdown-content {
  display: block;
  transition: all 500ms ease-in;
  align-items: center;
  color: white;
  border-radius: 0 0 8px 8px;
  z-index: 2000;
}

.dropdown-content:hover {
  border-radius: 0 0 8px 8px;
}
