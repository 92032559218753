.home-page {
  line-height: 23px;
}

.section-1 {
  display: flex;
  flex-direction: row;
  padding: 20px 0 20px 0;
  /* align-items: center; */
  justify-content: center;
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 20px 20px 105px;
  margin: auto;
}

.section-body {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  width: 70%;
  /* padding: 0 40px 0 40px; */
  margin: auto;
}

.section-1-right {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  /* padding-right: 40px; */
  padding-left: 20px;
  padding-top: 20px;
}

.section-1-left {
  width: 50%;
  /* padding-left: 40px; */
}

.section-2 {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 20px 0 20px 0;
}

.section-2-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.section-2-left > p {
  font-size: smaller;
  color: gray;
}

.section-2-right {
  width: 100%;
}

.home-pic {
  margin: 20px 0 5px 0;
  width: 80%;
  max-height: 25rem;
  border: solid 2px;
  box-shadow: 0 3px 5px #00000060;
}

.thumbnail {
  border: solid 2px black;
  box-shadow: 0 3px 5px #00000060;
  /* width: 30%; */
  max-height: 20rem;
  margin-right: 30px;
}

.btn-2-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 79%;
  margin: auto;
}

.btn-2 {
  display: flex;
  justify-content: center;
  padding: 10px 20px 10px 20px;
  background-color: blueviolet;
  color: #d3d3d3;
  border: none;
  width: 150px;
  text-decoration: none;
  font-size: medium;
  margin: 10px;
  box-shadow: 0 3px 5px #00000060;
  margin-left: 2rem;
}

.btn-2:hover {
  background-color: black;
  color: #d3d3d3;
}

@media screen and (max-width: 1100px) {
  .section-1 {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .section-2 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .section-1-left {
    width: 100%;
  }

  .section-header {
    justify-content: center;
  }

  .btn-2-container {
    justify-content: center;
  }

  .section-body {
    text-align: center;
  }
}

@media screen and (max-width: 1024px) {
  .section-header {
    padding: 20px;
  }
}

@media screen and (max-width: 765px) {
  .home-page {
    width: 100%;
    margin: auto;
  }

  .section-1-right {
    width: 90%;
  }

  .thumbnail {
    width: 85%;
    max-height: 15rem;
  }
}
