.about-page {
  width: 100%;
  margin: auto;
  line-height: 23px;
  /* background-image: url("../images/fern-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover; */
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: blueviolet;
  width: 100%;
  height: 7rem;
  /* color: #d3d3d3; */
  color: white;
  font-size: x-large;
  font-weight: 300;
  /* padding-top: 70px; */
  letter-spacing: 5px;
  box-shadow: 0 3px 5px #00000060;
  margin-top: 4rem;
  /* margin: 0; */
}

.about-pic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 75%;
  height: 20%;
  margin-top: 30px;
  border: solid 1px;
  box-shadow: 0 7px 7px #00000060;
}

.section-header-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
  padding: 30px 20px 20px 20px;
  margin: auto;
}

.board {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.board-section {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
  margin-top: 30px;
  /* align-items: center;
  text-align: center; */
  justify-content: center;
}

.board-section-1 {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  /* padding: 20px; */
}

.practitioner-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
}

.practitioner-section-2 {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 80%;
}

.practitioner-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 60%;
}

.board-section-header {
  margin: 30px 0 10px 0;
  text-align: center;
  width: 100%;
}

.practitioners {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin: auto;
}

.practitioner-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 30%; */
  /* padding-left: 60px; */
}

.practitioner-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  margin-top: 30px;
  margin-left: 6.25rem;
  border: solid 0.5px;
  box-shadow: 0 5px 7px #00000060;
}

.practitioner-img-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12rem;
  margin-top: 30px;
  margin-right: 6.25rem;
  border: solid 0.5px;
  box-shadow: 0 5px 7px #00000060;
}

/* @media screen and (max-width: 1100px) {
  .practitioners {
    margin: auto;
    padding-left: 0;
  }

  .practitioner-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-left: 0;
  }

  .practitioner-section-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 0;
  }

  .practitioner-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 75%;
  }

  

  .practitioner-img {
    margin-bottom: 50px;
    margin-left: 30px;
  }

  .board-section-header {
    text-align: center;
  }
} */

@media screen and (min-width: 1024px) {
  .practitioners {
    margin: 0;
    padding-left: 0;
  }

  .practitioner-section {
    display: flex;
    flex-direction: row;
    /* align-items: flex-start; */
    justify-content: center;
    text-align: start;
    width: 100%;
    margin-left: 0;
    width: 75%;
    margin: auto;
    /* padding-left: 150px; */
  }

  .practitioner-section-2 {
    display: flex;
    flex-direction: row-reverse;
    /* align-items: flex-start; */
    justify-content: center;
    text-align: start;
    width: 100%;
    margin-left: 0;
    width: 75%;
    margin: auto;
    /* padding-left: 150px; */
  }

  .practitioner-left {
    text-align: start;
  }

  .board-section-header {
    text-align: start;
    /* padding-left: 10px; */
  }
}

@media screen and (max-width: 756px) {
  .practitioner-section,
  .practitioner-section-2 {
    width: 90%;
  }

  .practitioner-left {
    /* font-size: small; */
    width: 60%;
    line-height: 15px;
  }

  .practitioner-body {
    justify-content: center;
    align-items: center;
    text-align: center;
    /* width: 70%; */
    font-size: x-small;
    margin: auto;
  }

  .practitioner-img,
  .practitioner-img-2 {
    width: 8rem;
    margin: 20px;
    margin-left: 1.5rem;
  }

  .practitioner-img-container-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 30%; */
    /* padding-right: 60px; */
  }

  .board-section-header {
    text-align: center;
  }
}
