.nav-card {
  display: block;
  background-color: #f1f1f1;
  border-radius: 8px;
  margin-top: 20px;
  font-weight: bold;
  width: 300px;
  text-align: center;
  margin: 0 2px 0 2px;
  padding-bottom: 10px;
}

.mbl-nav {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 60px;
  width: 30%;
  background-color: #181818;
  border-top: 2px lightgray;
  z-index: 0;
  opacity: 0;
}

.page-burger-open {
  opacity: 1;
  transition: all 500ms ease-in-out;
  z-index: 1;
}

.hamburger {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 20px;
  text-align: center;
  margin-left: 10px;
  width: 100%;
}

.btn2-burger {
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
  color: #d3d3d3;

  text-decoration: none;
  font-weight: 500;
  z-index: 1;

  background-color: rgb(1, 79, 1);
  color: #d3d3d3;
  border: none;
  /* width: 100px; */
  text-decoration: none;
  font-size: medium;
  font-weight: 500;

  /* margin-left: 38px;
  margin-bottom: 20px;
  bottom: 40px;
  box-shadow: 0 3px 5px #00000060; */
}

.btn2-burger:hover {
  background-color: blueviolet;
}

@media screen and (min-width: 1024px) {
  .mbl-nav {
    display: none;
  }

  .hamburger {
    display: none;
  }
}

@media screen and (max-width: 756px) {
  .hamburger {
    width: 100%;
  }
}
