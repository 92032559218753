.navbar {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0px;
  z-index: 3000;
  align-items: center;
  justify-content: flex-start;
  background-color: black;
  color: white;
  /* overflow: hidden; */
  /* border-bottom: 0.5px solid gray; */

  height: 4rem;
  width: 100%;
}

/* .navbar-container {
  display: flex;
} */

.nav-link {
  padding: 10px 20px 10px 20px;
  margin-bottom: 20px;
  color: #d3d3d3;

  text-decoration: none;
  font-weight: 500;
  z-index: 1;
}

.nav-link-active {
  color: #8f76c2;
}

.nav-link:hover {
  background-color: blueviolet;
  border-radius: 8px;
  color: white;
}

.btn2 {
  /* display: flex;
  align-items: center; */
  text-align: center;
  padding: 10px 20px 10px 20px;
  background-color: rgb(1, 79, 1);
  color: #d3d3d3;
  border: none;
  width: 100px;
  font-weight: 500;
  text-decoration: none;
  font-size: medium;
  margin-left: 20px;
  margin-bottom: 20px;
  bottom: 40px;
  box-shadow: 0 3px 5px #00000060;
}

.btn2:hover {
  background-color: blueviolet;
}

.title-header {
  width: 500px;
  margin-left: 20px;
}

.title {
  text-decoration: none;
  color: white;
  font-size: medium;
  font-weight: 700;
  width: 500px;
  /* width: 40%; */
}

.nav-desktop {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 70px;
  padding-right: 30px;
  margin-top: 10px;
}

.navbar-contact {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0px;
  z-index: 3000;
  align-items: center;
  justify-content: flex-start;
  background-color: black;
  color: white;
  right: 0;
  height: 4rem;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .nav-desktop {
    display: none;
  }
}

/* @media screen and (max-width: 756px) {
  .navbar {
    width: screen;
  }
} */
