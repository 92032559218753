@import url("navbar.css");
@import url("banner.css");
@import url("burger.css");
@import url("home.css");
@import url("footer.css");
@import url("carousel.css");
@import url("about.css");
@import url("kupuna.css");
@import url("dropdown.css");
@import url("contact.css");
@import url("blog.css");
@import url("articles.css");
@import url("events.css");
@import url("vidCarousel.css");

* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  letter-spacing: 1px;
}
