.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* height: 330px; */
  /* margin-top: 30px; */
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* flex-wrap: wrap; */
  width: 100%;
  height: 330px;
  margin: 0;
  padding-top: 40px;
  color: white;
}

.item1 {
  background-image: url("../images/dewBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.item2 {
  background-image: url("../images/laau-banner.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.item3 {
  background-image: url("../images/fernBanner.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.item-body {
  /* display: flex; */
  flex-wrap: wrap;
  text-align: center;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  background-color: #37373760;
  box-shadow: 0 3px 5px #00000060;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  white-space: normal;
  font-size: medium;
}

.btn {
  padding: 10px 20px 10px 20px;
  background-color: blueviolet;
  color: #d3d3d3;
  border: none;
  width: 100px;
  text-decoration: none;
  font-size: medium;
  margin-top: 10px;
  box-shadow: 0 3px 5px #00000060;
}

.btn:hover {
  background-color: black;
  color: #d3d3d3;
}

@media screen and (min-width: 1024px) {
  .item-body {
    /* display: flex; */
    flex-wrap: wrap;
    text-align: center;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    background-color: #37373760;
    box-shadow: 0 3px 5px #00000060;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    white-space: normal;
    /* font-size: small; */
  }
}

@media screen and (max-width: 756px) {
  .item1,
  .item2,
  .item3 {
    height: 330px;
    top: 34px;
  }

  .item-body {
    font-size: small;
    line-height: 19px;
  }
}
