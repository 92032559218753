.article-header {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  /* text-shadow: 0 3px 3px #30303075; */
  margin: auto;
  color: rgb(36, 35, 35);
}

/* .articles-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  margin: 10px 0 30px 0;
} */

/* .article-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 12rem;
  padding: 20px;
  align-items: center;
  text-align: center;
  box-shadow: 0 3px 5px #00000060;
  background-color: white;
  margin: 30px 0 30px 0;
} */

.card > p {
  color: gray;
  padding: 10px 0 20px 0;
  text-align: center;
}

.article {
  text-decoration: none;
  color: black;
  font-weight: 700;
  padding: 10px;
}

.button-2 {
  color: white;
  background: blueviolet;
  border-radius: 40px;
  padding: 10px 20px;
  text-decoration: none;
  justify-self: flex-end;
  width: fit-content;
  box-shadow: 0 3px 3px #00000060;
  /* margin: 20px; */
}

.button-2:hover {
  background-color: #4070ff;
}

@media screen and (min-width: 768px) {
  .articles-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
