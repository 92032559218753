.video-header {
  padding: 30px;
}

.vid-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: screen;
}

.vid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  height: 330px;
  color: white;
  margin: 0 40px;
}

.vid-indicators {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: black;
  width: 100%;
  /* margin-top: 40px; */
}

.arrow {
  width: 7rem;
  height: fit-content;
  background-color: white;
  margin: 20px 10px;
}

.arrow:hover {
  background-color: black;
  color: #d3d3d3;
}

@media screen and (max-width: 1024px) {
  .vid-item {
    margin: 0 10px;
  }
}
