.carousel {
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0 3px 5px #00000060;
  /* height: 400px; */
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;

  height: 320px;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
}

.indicators {
  display: flex;
  justify-content: center;
  z-index: 1000;
  opacity: 1;
  /* box-shadow: ; */
  /* opacity: 0; */
  /* bottom: 7rem; */
}

.indicators > button {
  margin: 5px;
  opacity: 0.8;
  z-index: 1;
  border: #d3d3d3;
  background-color: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

/* .arrow {
  margin: 5px;
  opacity: 0.8;
  z-index: 1;
  border: none;
  background-color: gray;
  width: 60px;
} */

.indicators > button.active {
  background-color: blueviolet;
  color: white;
}

.indicators > button:hover {
  background-color: black;
  color: white;
}

@media screen and (max-width: 756px) {
  .carousel {
    width: 100%;
  }
  .indicators {
    display: none;
  }
}
